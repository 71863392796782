import fb from '../../config/firebaseInstance';
import { createKidsHistory } from './historyActions';

export const types = {
  KIDS_SIGNIN_SUCCESS: 'KIDS_SIGNIN_SUCCESS',
  KIDS_SIGNIN_ERROR: 'KIDS_SIGNIN_ERROR'
};

const kidsCollection = 'kids';

export const kidSignInOrOutSuccess = (nowSignedIn, kid, user) => {
  createKidsHistory({
    username: user.name,
    detail: `Signing Child ${kid.name} (${kid.age}) ${nowSignedIn ? 'IN' : 'OUT'}.`
  });

  return {
    type: types.KIDS_SIGNIN_SUCCESS
  };
}

export const kidSignInOrOutError = (err) => {
  return {
    type: types.KIDS_SIGNIN_ERROR,
    err
  }
}

export const signKidInOrOut = (nowSignedIn, kid, user) => async dispatch => {
  const key = `${kid.registrationKey}-${kid.name}`;
  const changedFields = {
    signedIn: nowSignedIn
  };

  fb
    .firestore()
    .collection(kidsCollection)
    .doc(key)
    .update(changedFields)
    .then( () => dispatch( kidSignInOrOutSuccess(nowSignedIn, kid, user) ))
    .catch( err => dispatch( kidSignInOrOutError(err) ));
}
