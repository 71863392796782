import React, { Component, forwardRef } from 'react';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';

import { createAnnouncement } from '../store/actions/announcementsActions';

import AnnouncementsGrid from '../components/announcements/announcementsGrid';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime'

// MUI
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';

const normalizeDateField = announcements => {
  console.log(announcements);
  return announcements && announcements.map(announcement => {
    return {
      ...announcement,
      requestedAt: `${dayjs(announcement.requestedAt.toDate()).format('DD/M/YYYY h:mm:ss A')} (${dayjs(announcement.requestedAt.toDate()).fromNow()})`
    }
  })
}

const styles = (theme) => ({
  fullWidth: {
    width: '80%'
  }
});

class Announcements extends Component {
  render() {
    dayjs.extend(relativeTime);
    const { classes } = this.props;

    return (
      <div>
        <h1>Announcements</h1>

        <div>
          <Grid container spacing={1} alignItems="flex-end" className={classes.fullWidth}>
            <Grid item>
              <RecordVoiceOverIcon />
            </Grid>
            <Grid item className={classes.fullWidth}>
              <TextField id="input-with-icon-grid" label="Announcement" className={classes.fullWidth} />
            </Grid>
          </Grid>
        </div><br />

        <Button variant="contained" color="primary">Suggest</Button><br /><br />
        <AnnouncementsGrid user={this.props.user} announcements={this.props.outstandingAnnouncements} title="Outstanding" /><br /><br />
        <AnnouncementsGrid user={this.props.user} announcements={this.props.completedAnnouncements} title="Actioned" /><br /><br />
        <AnnouncementsGrid user={this.props.user} announcements={this.props.rejectedAnnouncements} title="Rejected" />
      </div>
      
    );
  }
}

const mapObjToArray = obj => {
  const objKeys = Object.getOwnPropertyNames(obj);
  const objArray = objKeys.map(objKey => obj[objKey]);
  return objArray;
}

const mapStateToProps = state => {
  let outstandingAnnouncements, completedAnnouncements, rejectedAnnouncements = [];
  console.log(state);
  if (state.firestore.data.announcements) {
    const announcements = normalizeDateField(mapObjToArray(state.firestore.data.announcements));
    outstandingAnnouncements = announcements.filter(announcement => !announcement.announced && !announcement.rejected);
    completedAnnouncements = announcements.filter(announcement => announcement.announced && !announcement.rejected);
    rejectedAnnouncements = announcements.filter(announcement => !announcement.announced && announcement.rejected);
  }
  
  return {
    outstandingAnnouncements,
    completedAnnouncements,
    rejectedAnnouncements
  }
}

export default compose(
  connect(mapStateToProps),
  withStyles(styles),
  firestoreConnect([
    { collection: 'announcements' }
  ])
)(Announcements);