import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = () => ({
  middle: {
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center',
    width: '100%',
    color: 'red'
  }
});

const CapabilityCheck = (props) => {
  if (!props.user.isLoaded) {
    return <div>Loading...</div>;
  }
  
  const visibleToAllUsers = !props.route.requiredCapability;
  const userHasRequiredCapability = (props.route.requiredCapability && props.user.capabilities.includes(props.route.requiredCapability)) || props.user.capabilities.includes('admin');
  if (visibleToAllUsers || userHasRequiredCapability) {
    return <props.component user={props.user} />;
  }

  return (
    <h1 className={props.classes.middle}>
      <SentimentVeryDissatisfiedIcon fontSize="large" /><br />
      Where you goin'?<br />
      Access denied, mate.
    </h1>
  );
}



const mapStateToProps = state => {
  return {
    authenticated: !state.firebase.auth.isEmpty,
    loading: !state.firebase.auth.isLoaded,
    user: state.firebase.profile
  };
}

export default compose(
  connect(mapStateToProps),
  withStyles(styles)  
  )(CapabilityCheck);
