import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import { createStore, applyMiddleware, compose } from 'redux'
import rootReducer from './store/reducers/rootReducer'
import { Provider } from 'react-redux'
import thunk from 'redux-thunk'
import { createFirestoreInstance, getFirestore } from 'redux-firestore';
import { ReactReduxFirebaseProvider, getFirebase  } from 'react-redux-firebase';
import firebaseInstance from './config/firebaseInstance';

const initialState = {};

const rrfConfig = {
  userProfile: 'users',
  useFirestoreForProfile: true,
  attachAuthIsReady: true
};

const middleware = [thunk.withExtraArgument({ getFirebase, getFirestore })];

const store = createStore(rootReducer,
  initialState,
  compose(
    applyMiddleware(...middleware)
  )
);

const rrfProps = {
  firebase: firebaseInstance,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance
};

const WrappedApp = () => (
  <Provider store={store}>
    <ReactReduxFirebaseProvider {...rrfProps}>
      <App />
    </ReactReduxFirebaseProvider>
  </Provider>
)

ReactDOM.render(<WrappedApp />, document.getElementById('root'));
registerServiceWorker();
