import React, { Component, forwardRef } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { createAnnouncement } from '../../store/actions/announcementsActions';

// MUI
import Paper from '@material-ui/core/Paper';

import MaterialTable from 'material-table';

// ICONS
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const AnnouncementsGrid = props => {
  
  return (
    <Paper>
      <MaterialTable
        columns={[
          { title: 'Announcement', field: 'text' },
          { title: 'Requested By', field: 'requestedBy' },
          { title: 'Requested At', field: 'requestedAt' }
        ]}
        data={props.announcements}
        icons={tableIcons}
        title={`${props.title} (${props.announcements && props.announcements.length})`}
        options={{
          paging: false,
          maxBodyHeight: "600px",
          headerStyle: {
            position: "sticky",
            top: "0"
          },
          rowStyle: {
            "&:hover": { backgroundColor: "#EEE" }
          },
          padding: 'dense',
          actionsColumnIndex: 99
        }}
      >
      </MaterialTable>
    </Paper>
  );
}



const mapDispatchToProps = dispatch => {
  return {
    createAnnouncement: (announcementText, user) => dispatch(createAnnouncement(announcementText, user))
  }
}

export default AnnouncementsGrid;