import React from 'react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime'
import historyTypes from '../../config/historyTypes';

// MUI
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

// MUI ICONS
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ChatIcon from '@material-ui/icons/Chat';
import ChildCareIcon from '@material-ui/icons/ChildCare';
import FlightLandIcon from '@material-ui/icons/FlightLand';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';

const getIconForHistoryType = type => {
  switch(type) {
    case historyTypes.AUTH:
      return <AccountCircleIcon />;

    case historyTypes.ANNOUNCEMENTS:
      return <RecordVoiceOverIcon />;

    case historyTypes.ARRIVALS:
      return <FlightLandIcon />;

    case historyTypes.KIDS:
      return <ChildCareIcon />;

    case historyTypes.SMS:
      return <ChatIcon />;

    default:
      return <HelpOutlineIcon />;
  }
};

const HistoryGrid = (props) => {
  
  dayjs.extend(relativeTime);

  return (
    <List>
      {props.historyRecords && props.historyRecords.map(record => (
        <div>
          <ListItem key={record.id} alignItems="flex-start">
            <ListItemIcon>
              {record && getIconForHistoryType(record.type)}
            </ListItemIcon>
            <ListItemText 
              primary={record.username}
              secondary={
                <React.Fragment>
                  <Typography 
                    component="span"
                    variant="body2"
                    color="textPrimary"
                  >
                    {record.detail}
                  </Typography><br />
                  {dayjs(record.timestamp.toDate()).format('DD/M/YYYY h:mm:ss A')} ({dayjs(record.timestamp.toDate()).fromNow()})
                </React.Fragment>
              }
            />
          </ListItem>
          <Divider variant="inset" component="li" />
        </div>
      ))}
    </List>
  );
}

export default HistoryGrid;