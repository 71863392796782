import fb from '../../config/firebaseInstance';
import { createSmsHistory } from './historyActions';

const configCollection = 'config';
const smsDoc = 'sms';

export const sendSmsToOneMobile = (msg, mobile, user) => async dispatch => {
  
}

export const sendSmsToAllMobiles = (msg, user) => async dispatch => {
  
}
