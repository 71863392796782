import fb from '../../config/firebaseInstance';
import { createArrivalsHistory } from './historyActions';

export const types = {
  ARRIVAL_SAVE_SUCCESS: 'ARRIVAL_SAVE_SUCCESS',
  ARRIVAL_SAVE_ERROR: 'ARRIVAL_SAVE_ERROR'
};

const registrationsCollection = 'registrations';

const makeWelcomeFromChangedFields = (changedFields, regoData) => {
  const newCarRego = changedFields.carRego;
  const newArrived = changedFields.arrived;
  const newKeysDelivered = changedFields.keysDelivered;
  const newWelcomePackDelivered = changedFields.welcomePackDelivered;

  const { carRego, arrived, keysDelivered, welcomePackDelivered } = regoData;

  const carRegoString = 
    carRego === newCarRego ? "" 
      : `Car Rego: [${carRego}] => [${newCarRego}] `;
  const arrivedString = 
    arrived === newArrived ? "" 
      : `Arrived: [${arrived}] => [${newArrived}] `;
  const keysDeliveredString = 
    keysDelivered === newKeysDelivered ? "" 
      : `Keys Delivered: [${keysDelivered}] => [${newKeysDelivered}] `;
  const welcomePackDeliveredString = 
    welcomePackDelivered === newWelcomePackDelivered ? "" 
      : `Welcome Pack Delivered: [${welcomePackDelivered}] => [${newWelcomePackDelivered}]`;

  return (
    `${carRegoString}
  ${arrivedString}
  ${keysDeliveredString}
  ${welcomePackDeliveredString}`
  );
}

export const saveArrivalDataSuccess = (changedFields, regoData, user) => {
  console.log('IN SUCCESS');
  
  createArrivalsHistory({
    username: user.name,
    detail: `Welcomed ${regoData.names}! ${makeWelcomeFromChangedFields(changedFields, regoData)}`
  });

  return {
    type: types.ARRIVAL_SAVE_SUCCESS
  };
}

export const saveArrivalDataError = (err) => {
  return {
    type: types.ARRIVAL_SAVE_ERROR,
    err
  }
}

export const saveArrivalData = (arrivalData) => async dispatch => {
  const { changedFields, regoData, user} = arrivalData;
  console.log('IN SAVE ARRIVAL DATA')
  fb
    .firestore()
    .collection(registrationsCollection)
    .doc(regoData.key)
    .update(changedFields)
    .then( () => dispatch( saveArrivalDataSuccess(changedFields, regoData, user) ))
    .catch( err => dispatch( saveArrivalDataError(err) ));

  console.log("DISPATCHED!");
}
