import React, { Component } from 'react';
import clsx from 'clsx';
import { Link as RouteLink, Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux';
import { compose } from 'redux';

import routes from '../config/routes';
import Login from '../pages/login';
import { logoutUser } from '../store/actions/authActions';

// MUI
import withStyles from '@material-ui/core/styles/withStyles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Tooltip from '@material-ui/core/Tooltip';
import CapabilityCheck from '../util/capabilityCheck';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
        Showtime
      {' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const drawerWidth = 240;

const styles = (theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '0 8px',
    alignItems: 'start',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  footer: {
    padding: theme.spacing(3, 2),
    marginTop: 'auto',
    backgroundColor: theme.palette.grey[200],
  },
  logo: {
    maxWidth: '80%',
  }
});

export class Dashboard extends Component {
  state = {
    drawerOpen: false
  };

  mainListItems() {
    if (!this.props.user.isLoaded) {
      return null;
    }

    return routes.map(route => {
      const visibleToAllUsers = !route.requiredCapability;
      const userHasRequiredCapability = (route.requiredCapability && this.props.user.capabilities.includes(route.requiredCapability)) || this.props.user.capabilities.includes('admin');
      if (visibleToAllUsers || userHasRequiredCapability) {
        return (
          <ListItem key={route.path} button divider={true} component={RouteLink} to={route.path}>
            <Tooltip title={route.label}>
              <ListItemIcon>
                {route.icon}
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary={route.label} />
          </ListItem>
        );
      }
      
      return null;
    });
  }
    
  render() {
    if (this.props.loading) {
      return <div>Loading...</div>
    }

    if (this.props.authenticated) {
      return this.renderAuthenticatedDashboard();
    }

    return <Login />
  }

  renderAuthenticatedDashboard() {
    const classes = this.props.classes;
    const handleDrawerOpen = () => {
      this.setState({ drawerOpen: true });
    };
    const handleDrawerClose = () => {
      this.setState({ drawerOpen: false });
    };

    const { name } = this.props.user;

    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="absolute" className={clsx(classes.appBar, this.state.drawerOpen && classes.appBarShift)}>
          <Toolbar className={classes.toolbar}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              className={clsx(classes.menuButton, this.state.drawerOpen && classes.menuButtonHidden)}
            >
              <MenuIcon />
            </IconButton>
            <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
              Hi {name}!
            </Typography>
            <IconButton 
              color="inherit"
              onClick={this.props.logoutUser}
            >
              <Tooltip title="Logout">
                <ExitToAppIcon />
              </Tooltip>
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          classes={{
            paper: clsx(classes.drawerPaper, !this.state.drawerOpen && classes.drawerPaperClose),
          }}
          open={this.state.drawerOpen}
        >
          <div className={classes.toolbarIcon}>
            <img src={`/images/logo-login.png`} alt="logo" title="YCF Showtime" className={classes.logo} />
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          <List>{this.mainListItems()}</List>
        </Drawer>
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Container maxWidth="xl" className={classes.container}>
            <Switch>
              {routes.map(route => {
                return (
                  <Route exact path={route.path} key={route.path} render={
                    (props) => {
                      return <CapabilityCheck {...props} component={route.component} route={route} />
                    }
                  } />
                );
              })}
            </Switch>
          </Container>
          <footer className={classes.footer}>
            <Container maxWidth="sm">
              <Copyright />
            </Container>
          </footer>
        </main>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    authenticated: !state.firebase.auth.isEmpty,
    loading: !state.firebase.auth.isLoaded,
    user: state.firebase.profile
  };
}

const mapDispatchToProps = dispatch => {
  return {
    logoutUser: () => dispatch(logoutUser())
  };
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
  )(Dashboard);