import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom'
import './App.css';

// MUI
import { ThemeProvider } from '@material-ui/core/styles';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import themeFile from './util/theme';

// Redux

// Components
import Dashboard from './layout/dashboard';

const theme = createMuiTheme(themeFile);

function App() {

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Dashboard />
      </Router>
    </ThemeProvider>
  );
}

export default App;