import red from '@material-ui/core/colors/red';

export default {
  palette: {
    primary: red,
    secondary: red,
  },
  typography: {
    useNextVariants: true
  }
};