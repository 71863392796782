import fb from '../../config/firebaseInstance';
import { createAuthHistory } from './historyActions';

export const types = {
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS'
};

const usersCollection = 'users';

export const loginUserSuccess = userCredentials => {
  fb
    .firestore()
    .collection(usersCollection)
    .doc(userCredentials.user.uid)
    .get()
    .then( doc => {
      if(doc.exists) {
        const userData = doc.data();
        createAuthHistory({
          username: userData.name,
          uid: userCredentials.user.uid,
          detail: 'Logged In Successfully'
        });
      }
    });

  return {
    type: types.LOGIN_SUCCESS,
    user: userCredentials
  };
};

export const loginUserError = err => {
  return {
    type: types.LOGIN_ERROR,
    err
  };
};

export const logoutUserSuccess = () => {
  fb.logout();
  return {
    type: types.LOGOUT_SUCCESS
  };
};

export const loginUser = credentials => async dispatch => {
  const { email, password } = credentials;
  fb
    .auth()
    .signInWithEmailAndPassword(email, password)
    .then( userCredentials => dispatch( loginUserSuccess(userCredentials) ) )
    .catch( err => dispatch( loginUserError(err) ) );
}

export const logoutUser = () => async dispatch => {
  const uid = fb.auth().currentUser.uid;
  fb
    .firestore()
    .collection(usersCollection)
    .doc(uid)
    .get()
    .then( doc => {
      if(doc.exists) {
        const userData = doc.data();
        createAuthHistory({
          username: userData.name,
          uid: uid,
          detail: 'Logged Out'
        });
      }
    });

  fb
    .auth()
    .signOut()
    .then( () => dispatch( logoutUserSuccess() ) )
    .catch(err => console.log(err));
}