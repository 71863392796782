import React, { Component } from 'react';
import { compose } from 'redux';

// MUI
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import Checkbox from '@material-ui/core/Checkbox';
import { Button } from '@material-ui/core';


const styles = (theme) => ({
  root: {
    padding: '15px 25px'
  }
});

export class ArrivalsDetailPanel extends Component {
  state = {
    carRego: '',
    arrived: false,
    keysDelivered: false,
    welcomePackDelivered: false
  }

  componentDidMount() {
    const { regoData }= this.props;

    if (regoData) {
      this.setState ({
        carRego: regoData.carRego,
        arrived: !!regoData.arrived,
        keysDelivered: !!regoData.keysDelivered,
        welcomePackDelivered: !!regoData.welcomePackDelivered
      });
    }
  }

  handleCheckbox = event => {
    this.setState({
      [event.target.name]: event.target.checked
    });
  }

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  handleSubmit = event => {
    event.preventDefault();
    this.props.saveArrivalData({
      changedFields: this.state,
      regoData: this.props.regoData,
      user: this.props.user
    });
  }

  render() {
    const { classes, regoData }= this.props;
    
    return (
      <Paper className={classes.root}>
        <form noValidate onSubmit={this.handleSubmit}>
          <GridList>
            <GridListTile>
              <div>
                <strong className={classes.infoLabel}>ID: </strong><span className={classes.info}>{regoData.id}</span>
              </div>
              <div>
                <strong className={classes.infoLabel}>Cabin Number: </strong><span className={classes.info}>{regoData.cabinNumber}</span>
              </div><br />
              <TextField name="carRego" label="Car Rego" value={this.state.carRego} onChange={this.handleChange} />
            </GridListTile>
            <GridListTile>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.arrived}
                      onChange={this.handleCheckbox}
                      value={this.state.arrived}
                      name="arrived"
                      color="primary"
                    />
                  }
                  label="Arrived"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.keysDelivered}
                      onChange={this.handleCheckbox}
                      value={this.state.keysDelivered}
                      name="keysDelivered"
                      color="primary"
                    />
                  }
                  label="Keys Delivered"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.welcomePackDelivered}
                      onChange={this.handleCheckbox}
                      value={this.state.welcomePackDelivered}
                      name="welcomePackDelivered"
                      color="primary"
                    />
                  }
                  label="Welcome Pack Delivered"
                />
              </FormGroup>
            </GridListTile>
          </GridList>
          <div className={classes.saveButton}>
            <Button variant="contained" color="primary" type="submit">
              Save
            </Button>
          </div>
        </form>
      </Paper>
    );
  }
}

export default compose(
  withStyles(styles)
)(ArrivalsDetailPanel);