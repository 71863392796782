import React, { Component } from 'react';
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import HistoryGrid from '../components/history/historyGrid';
import { Paper } from '@material-ui/core';

class History extends Component {
  
  render() {

    return (
      <div>
        <h1>History</h1>
        <Paper>
          <HistoryGrid historyRecords={this.props.historyRecords} />
        </Paper>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    historyRecords: state.firestore.ordered.history
  }
}

export default compose(
  connect(mapStateToProps),
  firestoreConnect([
    { collection: 'history', limit: 100, orderBy: ['timestamp', 'desc']}
  ])
)(History);