import React, { Component } from 'react';
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'

import { sendSmsToOneMobile, sendSmsToAllMobiles } from '../store/actions/smsActions';

// MUI
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import Paper from '@material-ui/core/Paper';

// ICONS
import SmartphoneIcon from '@material-ui/icons/Smartphone';
import ChatIcon from '@material-ui/icons/Chat';
import Icon from '@material-ui/core/Icon';

const styles = (theme) => ({
  row: {
    height: 100
  },
  margin: {
    margin: theme.spacing(4),
  },
  fullWidth: {
    width: '95%'
  },
  button: {
    margin: theme.spacing(1),
  },
  centerButton: {
    textAlign: 'center'
  }
});

class Sms extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div>
        <h1>SMS</h1>
        <Paper>
          <GridList cellHeight={300} cols={3} className={classes.row}>
            <GridListTile cols={2}>
              <div className={classes.margin}>
                <Grid container spacing={1} alignItems="flex-end" className={classes.fullWidth}>
                  <Grid item>
                    <SmartphoneIcon />
                  </Grid>
                  <Grid item>
                    <TextField label="Mobile" inputProps={{maxlength: "10", pattern: "04[0-9]{8}"}} title={"Must be a mobile number"} />
                  </Grid>
                </Grid>
              </div>
            </GridListTile>
            <GridListTile cols={1}>
              <div className={classes.margin}>
              Balance: {this.props.balance}
              Number of Mobiles: {this.props.numberOfMobileNumbers}
              </div>
            </GridListTile>
          </GridList>
          <GridList cellHeight={300} cols={3} className={classes.row}>
            <GridListTile cols={3}>
              <div className={classes.margin}>
                <Grid container spacing={1} alignItems="flex-end" className={classes.fullWidth}>
                  <Grid item>
                    <ChatIcon />
                  </Grid>
                  <Grid item>
                    <TextField label="Message" multiline />
                  </Grid>
                </Grid>
              </div>
            </GridListTile>
          </GridList>
          <div className={classes.centerButton}>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              endIcon={<Icon>send</Icon>}
            >
              Send
            </Button>
          </div>
        </Paper>
      </div>
      
    );
  }
}

const mapStateToProps = state => {
  if(state.firestore.data && state.firestore.data.config && state.firestore.data.config.sms) {
    const { balance, numberOfMobileNumbers } = state.firestore.data.config.sms;
    return {
      balance,
      numberOfMobileNumbers
    };
  }

  return state;
}

const mapDispatchToProps = dispatch => {
  return {
    sendSmsToOneMobile: (msg, mobile, user) => dispatch(sendSmsToOneMobile(msg, mobile, user)),
    sendSmsToAllMobiles: (msg, user) => dispatch(sendSmsToAllMobiles(msg, user))
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  firestoreConnect([
    { 
      collection: 'config',
      doc: 'sms'
    }
  ])
)(Sms);