import React, { Component, forwardRef } from 'react';
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'

import { signKidInOrOut } from '../store/actions/kidsActions';

// MUI
import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';

import MaterialTable from 'material-table';

// ICONS
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const normalizeSignedInStatus = kids => {
  return kids.map(kid => {
    return {
      ...kid,
      signedIn: !!kid.signedIn,
      tshirtSize: kid.tshirtSize || ''
    }
  });
};

const formatParentsColumn = kid => {
  return (
    <div>
      <span>{kid.p1Name} {kid.p1Surname} ({kid.p1Mobile})</span>
      {kid.p2Name && (<span> &amp; {kid.p2Name} {kid.p2Surname} ({kid.p2Mobile})</span>)}
    </div>
)};

const styles = (theme) => ({

});

class Kids extends Component {

  signInOrOutButton(kid) {
    const signedInButton = (
      <Button variant="contained" color="default" disabled={!kid.signedIn} onClick={(e) => this.props.signKidInOrOut(!kid.signedIn, kid, this.props.user)}>
        SIGN OUT
      </Button>
    );

    const signedOutButton = (
      <Button variant="contained" color="primary" disabled={kid.signedIn} onClick={(e) => this.props.signKidInOrOut(!kid.signedIn, kid, this.props.user)}>
        SIGN IN
      </Button>
    );
    return (
      <div>
        {kid.signedIn ? signedInButton : signedOutButton}
      </div>
    );
  };

  render() {
    const kids = normalizeSignedInStatus(this.props.kids);
    return (
      <div>
        <h1>Kids</h1>
        <MaterialTable
          columns={[
            {
              hidden: true,
              field: 'id',
              customFilterAndSearch: (term, rowData) => term === rowData.id.toString(),
              searchable: true
            },
            {
              title: 'Name', 
              render: rowData => <div><strong>{rowData.name}</strong> ({rowData.age})</div>,
              customFilterAndSearch: (term, rowData) => rowData.name.toLowerCase().indexOf(term.toLowerCase()) > -1 || rowData.age.toString().indexOf(term.toLowerCase()) > -1
            },
            { title: 'Allergies', field: 'allergies' },
            { title: 'Health Conditions', field: 'healthConditions' },
            { title: 'Medication', field: 'meds' },
            {
              title: 'Parents',
              render: rowData => formatParentsColumn(rowData),
              customFilterAndSearch: (term, rowData) => rowData.p1Name.toLowerCase().indexOf(term.toLowerCase()) > -1 || rowData.p2Name.toLowerCase().indexOf(term.toLowerCase()) > -1
            },
            { title: 'T-Shirt Size', field: 'tshirtSize' },
            {
              title: 'Actions',
              filtering: false,
              sorting: false,
              searchable: false,
              removable: false,
              render: rowData => this.signInOrOutButton(rowData)
            }
          ]}
          data={kids}
          icons={tableIcons}
          options={{
            paging: false,
            showTitle: false,
            maxBodyHeight: "600px",
            headerStyle: {
              position: "sticky",
              top: "0"
            },
            rowStyle: {
              "&:hover": { backgroundColor: "#EEE" }
            },
            padding: 'dense',
            actionsColumnIndex: 99
          }}
        >
        </MaterialTable>
      </div>
    );
  }
}

const mapStateToProps = state => {
  let kidsArray = []
  if (state.firestore.data.kids) {
    const kids = state.firestore.data.kids
    const kidKeys = Object.getOwnPropertyNames(kids);
    kidsArray = kidKeys.map(kidKey => kids[kidKey]);
  }
  
  return {
    kids: kidsArray
  }
}

const mapDispatchToProps = dispatch => {
  return {
    signKidInOrOut: (nowSignedIn, kid, user) => dispatch(signKidInOrOut(nowSignedIn, kid, user))
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  firestoreConnect([
    { collection: 'kids' }
  ])
)(Kids);