import React, { Component, forwardRef } from 'react';
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'

import { saveArrivalData } from '../store/actions/arrivalsActions';

import ArrivalsDetailPanel from '../components/arrivals/arrivalsDetailPanel';

// MUI
import MaterialTable from 'material-table';

// ICONS
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const mapRegosToArrivals = regos => {
  return regos && regos.map(rego => {
    return {
      p1Name: rego.p1Name,
      p1Surname: rego.p1Surname,
      p2Name: rego.p2Name,
      p2Surname: rego.p2Surname,
      names: `${rego.p1Name} ${rego.p1Surname}, ${rego.p2Name}, ${rego.p2Surname}`,
      accommodation: rego.dayVisitShort
        ? `Day Visit (${rego.dayVisitShort})` 
        : `${rego.accommodation.split(' - ')[0]}`,
      kids: rego.kids,
      kidsNames: rego.kidsNames,
      key: rego.key,
      id: rego.id,
      cabinNumber: rego.cabinNumber,
      arrived: rego.arrived,
      carRego: rego.carRego,
      keysDelivered: rego.keysDelivered,
      welcomePackDelivered: rego.welcomePackDelivered
    }
  })
};

const splitKidsToCellContent = kids => {
  if (kids.length === 0) {
    return null
  }

  return kids.map(kid => {
    return (
      <div key={kid.name}>
        <strong>{kid.name}</strong> ({kid.age}){kid.excursion && <small> <small>(excursion)</small></small>}
      </div>
      );
  });
};

const formatNamesColumn = regoRowData => {
  return (
  <div>
    <span><strong>{regoRowData.p1Name} {regoRowData.p1Surname}</strong></span>
    {regoRowData.p2Name && (<span> &amp; <br /><strong>{regoRowData.p2Name} {regoRowData.p2Surname}</strong></span>)}
  </div>
)};

class Arrivals extends Component {
  render() {
    const regoData = mapRegosToArrivals(this.props.registrations);
    return (
      <div>
        <h1>Arrivals</h1>
        <MaterialTable
          columns={[
            { 
              title: 'Names', 
              field: 'names',
              render: rowData => formatNamesColumn(rowData),
              customFilterAndSearch: (term, rowData) => rowData.names.toLowerCase().indexOf(term.toLowerCase()) > -1
            },
            { title: 'Accommodation', field: 'accommodation' },
            { 
              title: 'Kids',
              field: 'kids',
              render: rowData => splitKidsToCellContent(rowData.kids),
              customFilterAndSearch: (term, rowData) => rowData.kidsNames.toLowerCase().indexOf(term.toLowerCase()) > -1
            }
          ]}
          data={regoData}
          icons={tableIcons}
          options={{
            paging: false,
            showTitle: false,
            maxBodyHeight: "600px",
            headerStyle: {
              position: "sticky",
              top: "0"
            },
            rowStyle: {
              "&:hover": { backgroundColor: "#EEE" }
            },
            padding: 'dense'
          }}
          onRowClick={(event, rowData, togglePanel) => togglePanel()}
          detailPanel={rowData => <ArrivalsDetailPanel regoData={rowData} user={this.props.user} saveArrivalData={this.props.saveArrivalData} />}
        >
        </MaterialTable>
      </div>
    );
  }
}

const mapStateToProps = state => {
  let regosArray = []
  if (state.firestore.data.registrations) {
    const regos = state.firestore.data.registrations
    const regoKeys = Object.getOwnPropertyNames(regos);
    regosArray = regoKeys.map(regoKey => regos[regoKey]);
  }
  
  return {
    registrations: regosArray
  }
}

const mapDispatchToProps = dispatch => {
  return {
    saveArrivalData: arrivalData => dispatch(saveArrivalData(arrivalData))
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect([
    { collection: 'registrations', limit: 100 }
  ])
)(Arrivals);