import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

const config = {
  apiKey: "AIzaSyCV3tIGZPFnUhXhNORVZP0jOLbXq2Y7zPM",
  authDomain: "ycf-showtime.firebaseapp.com",
  databaseURL: "https://ycf-showtime.firebaseio.com",
  projectId: "ycf-showtime",
  storageBucket: "ycf-showtime.appspot.com",
  messagingSenderId: "898117553238",
  appId: "1:898117553238:web:e1e15c4df7bb56ca3d7ff7",
  measurementId: "G-PRLYZCYQHS"
};

firebase.initializeApp(config);
firebase.firestore().settings({});

export default firebase;