import React from 'react';

// Page / Route Components
import Home from "../pages/home";
import Admin from "../pages/admin";
import Announcements from "../pages/announcements";
import Arrivals from "../pages/arrivals";
import History from "../pages/history";
import Kids from "../pages/kids";
import Sms from "../pages/sms";

// Icons
import HomeIcon from '@material-ui/icons/Home';
import SettingsIcon from '@material-ui/icons/Settings';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import FlightLandIcon from '@material-ui/icons/FlightLand';
import ListAltIcon from '@material-ui/icons/ListAlt';
import ChildCareIcon from '@material-ui/icons/ChildCare';
import ChatIcon from '@material-ui/icons/Chat';


const routes = [
  {
    path: '/',
    component: Home,
    label: 'Home',
    icon: (<HomeIcon />)
  },
  {
    path: '/admin',
    component: Admin,
    label: 'Admin',
    requiredCapability: 'admin',
    icon: (<SettingsIcon />)
  },
  {
    path: '/announcements',
    component: Announcements,
    label: 'Announcements',
    icon: (<RecordVoiceOverIcon />)
  },
  {
    path: '/arrivals',
    component: Arrivals,
    label: 'Arrivals',
    requiredCapability: 'arrivals',
    icon: (<FlightLandIcon />)
  },
  {
    path: '/kids',
    component: Kids,
    label: 'Kids',
    requiredCapability: 'kids',
    icon: (<ChildCareIcon />)
  },
  {
    path: '/sms',
    component: Sms,
    label: 'SMS',
    requiredCapability: 'sms',
    icon: (<ChatIcon />)
  },
  {
    path: '/history',
    component: History,
    label: 'History',
    icon: (<ListAltIcon />)
  }
]

export default routes;