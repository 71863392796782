import fb from '../../config/firebaseInstance';
import historyTypes from '../../config/historyTypes';

const historyCollection = 'history';

const createHistoryRecord = historyRecord => {
  fb
    .firestore()
    .collection(historyCollection)
    .add({
      ...historyRecord,
      timestamp: fb.firestore.FieldValue.serverTimestamp()
    })
};


export const types = {
  AUDIT_LOG_ADDED: 'AUDIT_LOG_ADDED'
};

export const createAuthHistory = historyRecord => {
  createHistoryRecord({
    ...historyRecord,
    type: historyTypes.AUTH
  });
  
  return {
    type: types.AUDIT_LOG_ADDED,
    historyRecord
  };
};

export const createArrivalsHistory = historyRecord => {
  createHistoryRecord({
    ...historyRecord,
    type: historyTypes.ARRIVALS
  });

  return {
    type: types.AUDIT_LOG_ADDED,
    historyRecord
  };
};

export const createKidsHistory = historyRecord => {
  createHistoryRecord({
    ...historyRecord,
    type: historyTypes.KIDS
  });

  return {
    type: types.AUDIT_LOG_ADDED,
    historyRecord
  };
};

export const createSmsHistory = historyRecord => {
  createHistoryRecord({
    ...historyRecord,
    type: historyTypes.SMS
  });

  return {
    type: types.AUDIT_LOG_ADDED,
    historyRecord
  };
};

export const createAnnouncementsHistory = historyRecord => {
  createHistoryRecord({
    ...historyRecord,
    type: historyTypes.ANNOUNCEMENTS
  });

  return {
    type: types.AUDIT_LOG_ADDED,
    historyRecord
  };
};